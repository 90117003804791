
import { Options, Vue } from "vue-class-component";
import HomepageService from "../../service/HomepageService";
import Toaster from "../../helpers/Toaster";
import moment from "moment";
import { camelCase } from "lodash";
import { branchDetail } from "../../pages/announcements/IAnnounce";
import AnnouncementDialog from "../../components/AnnouncementDialog.vue";

@Options({
  components: { AnnouncementDialog },
  title: "Dashboard",
})
export default class Homepage extends Vue {
  private searchDialog = false;
  private activeType = "";
  private myStores: Array<branchDetail> = [];
  private announcePath = "";
  private date1 = "";
  private date2 = "";
  private dateTimeNow = "";
  private searchDate = "";
  private branchName = "";
  private announceToday = 0;
  private avgValue = "$ 0.00";
  private avgValueMtd = "$ 0.00";
  private buybackAmount = "0.00";
  private buybackAmountMtd = "0.00";
  private dryCleaning = "$ 0.00";
  private dryCleaningMtd = "$ 0.00";
  private productToday = "$ 0.00";
  private productWeekly = "$ 0.00";
  private getEmployeeHrs = 0;
  private getItemsPending = 0;
  private getItemsRack = 0;
  private getItemsReady = 0;
  private getTotalEmployee = 0;
  private walkInCustomer = 0;
  private corporateCust  = 0;
  private getTotalLabourCostMtd = "$ 0.00";
  private leftToday = "0.00";
  private onTime = "0.00 %";
  private onTimeMtd = "0.00 %";
  private redoInvoice = "0.00";
  private redoInvoiceMtd = "0.00";
  private refundAmount = "0.00";
  private refundAmountMtd = "0.00";
  private rushService = "# 0";
  private rushServiceMtd = "# 0";
  private announcements = [];
  private salesQtr = [];
  private salesYtdly = [];
  private topServicesLabel = [];
  private topServicesAmount = [];
  private customersVisitings = [];
  private departLabel = [];
  private departTotals = [];
  private actualData = [];
  private planData = [];
  private getTopDepartment = [];
  private storeMagic = "$ 0.00";
  private storeMagicMtd = "$ 0.00";
  private totalInvAmt = "$ 0.00";
  private totalInvAmtMtd = "$ 0.00";
  private totalLabourCost = "$ 0.00";
  private homepageTxn;
  private toast;
  private searchForm = {
    date1: "",
    date2: "",
    filter: "None",
    assignedBranchId: {
      code: "",
      mall: "",
    },
  };

  // private multiAxisOptions = {
  //   responsive: true,
  //   tooltips: {
  //     mode: "index",
  //     titleFontSize: 30,
  //     bodyFontSize: 22,
  //     intersect: true,
  //   },
  // };

  private storeActivityChartOptions = {
  indexAxis: 'x',
  responsive: true,
  responsiveAnimationDuration: 500,
  };


  private announceStatus = "";
  private annoucementId = "";

  //OPEN ANNOUNCEMENT DIALOG BOX
  announcementDialog(id) {
    this.annoucementId = id;
    this.announceStatus = "previewAnnouncement";
  }

  //USED TO UPDATE THE PIN STATUS
  updateAnnounceStatus() {
    this.announceStatus = "";
    this.getHomepageDetails();
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.homepageTxn = new HomepageService();
    this.announcePath = this.homepageTxn.getBaseURL() + "uploads/announcement/";
    this.toast = new Toaster();
  }

  mounted() {
    this.getHomepageDetails();
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  formatDate(value) {
    if (value) {
      return moment(String(value)).format("DD-MM-YYYY");
    }
  }

  formatTime(value) {
    if (value) {
      const time = moment.duration(value);
      return moment(String(time), "HH:mm:ss").format("hh:mm A");
    }
  }

  getHomepageDetails() {
    this.homepageTxn.getHompageDetails(this.searchForm).then((res) => {
      const d = this.camelizeKeys(res);
      this.dateTimeNow = d.dateTimeNow;
      this.date1 = d.date1;
      this.date2 = d.date2;
      this.announcements = d.announcements;
      this.activeType = d.activeType;
      this.customersVisitings = d.customersVisitings;
      this.departLabel = d.departLabel;
      this.departTotals = d.departTotals;
      this.actualData = d.actualData;
      this.planData = d.planData;
      this.branchName = d.branchName;
      this.topServicesLabel = d.topServices[0];
      this.topServicesAmount = d.topServices[1];
      this.getTopDepartment = d.getTopDepartment;
      this.announceToday = d.announceToday;
      this.avgValue = d.dashData[0].avgValue;
      this.avgValueMtd = d.dashData[0].avgValueMtd;
      this.buybackAmount = d.dashData[0].buybackAmount;
      this.buybackAmountMtd = d.dashData[0].buybackAmountMtd;
      this.dryCleaning = d.dashData[0].dryCleaning;
      this.dryCleaningMtd = d.dashData[0].dryCleaningMtd;
      this.productToday = d.dashData[0].getProductsToday;
      this.productWeekly = d.dashData[0].getProductsMtd;
      this.getEmployeeHrs = d.dashData[0].getEmployeeHrs;
      this.getItemsPending = d.dashData[0].getItemsPending;
      this.getItemsRack = d.dashData[0].getItemsRack;
      this.getItemsReady = d.dashData[0].getItemsReady;
      this.getTotalEmployee = d.dashData[0].getTotalEmployee;
      this.walkInCustomer  = d.dashData[0].walkInCustomer;
      this.corporateCust   = d.dashData[0].corporateCust ;
      this.getTotalLabourCostMtd = d.dashData[0].getTotalLabourCostMtd;
      this.leftToday = d.dashData[0].leftToday;
      this.onTime = d.dashData[0].onTime;
      this.onTimeMtd = d.dashData[0].onTimeMtd;
      this.redoInvoice = d.dashData[0].redoInvoice;
      this.redoInvoiceMtd = d.dashData[0].redoInvoiceMtd;
      this.refundAmount = d.dashData[0].refundAmount;
      this.refundAmountMtd = d.dashData[0].refundAmountMtd;
      this.rushService = d.dashData[0].rushService;
      this.rushServiceMtd = d.dashData[0].rushServiceMtd;
      this.salesQtr = d.dashData[0].salesQtr;
      this.salesYtdly = d.dashData[0].salesYtdly;
      this.storeMagic = d.dashData[0].storeMagic;
      this.storeMagicMtd = d.dashData[0].storeMagicMtd;
      this.totalInvAmt = d.dashData[0].totalInvAmt;
      this.totalInvAmtMtd = d.dashData[0].totalInvAmtMtd;
      this.totalLabourCost = d.dashData[0].totalLabourCost;
    });
  }

  get serviceChartData() {
    return {
      labels: this.topServicesLabel,
      datasets: [
        {
          label: "Service Sold $",
          backgroundColor: "#004C97",
          yAxisID: "y-axis-1",
          data: this.topServicesAmount,
        },
      ],
    };
  }

  get visitings() {
    return {
      labels: [
        "Morn (8AM - 12PM)",
        "After (12PM - 5PM)",
        "Even (5PM - 9PM)",
      ],
      datasets: [
        {
          label: "Customer Visits",
          backgroundColor: "#ffc107",
          yAxisID: "y-axis-1",
          data: this.customersVisitings,
        },
      ],
    };
  }

  get departmentChart() {
    return {
      labels: this.departLabel,
      datasets: [
        {
          label: "Department Sold $",
          backgroundColor: "#28a745",
          yAxisID: "y-axis-1",
          data: this.departTotals,
        },
      ],
    };
  }

  get multiAxisData() {
    return {
      labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      datasets: [
        {
          label: "Planned Sales ($)",
          backgroundColor: "#004C97",
          data: this.planData,
        },
        {
          label: "Actual Sales ($)",
          backgroundColor: "#ffc107",
          data: this.actualData,
        },
      ],
    };
  }

  formatAmount(value) {
    value = Number(value);

    let balance = 0;

    if (value != "") {
      balance = value.toFixed(2);
    }
    return balance;
  }

  getAnnouncemnetIcon(icon) {
    let notification = "";

    if (icon == "Promotional") {
      notification = "promotion.png";
    } else if (icon == "Informative") {
      notification = "info.png";
    } else if (icon == "News") {
      notification = "news.png";
    } else {
      notification = "alert.png";
    }

    return notification;
  }

  checkTimePassed(dateAgo) {
    return moment(dateAgo, "YYYY-MM-DD HH:mm:ss").fromNow(true) + " ago";
  }

  openSearchForm() {
    this.searchDialog = true;

    this.homepageTxn.getBranchBasic().then((data) => {
      this.myStores = data.branches_list;
    });
  }

  searchDashboard() {
    this.getHomepageDetails();
    this.searchDialog = false;
  }

  clearFilterTag() {
    this.searchForm.filter = "None";
  }

  clearDates() {
    this.searchForm.date1 = "";
    this.searchForm.date2 = "";
  }
}
