
import { Options, Vue } from "vue-class-component";
import DescriptionTag from "../../service/DescriptionTag";
import Toaster from "../../helpers/Toaster";

@Options({
  components: {},
})
export default class DescriptionTags extends Vue {
  private imagePath = "";
  private lists = [];
  private DescriptionTag;
  private selectedCategory;
  private selectedCategoryObj;
  private productStatus = "";
  private productDialog = false;
  private statusDialog = false;
  private checkPagination = true;
  private totalRecords = 0;
  private keyword = "";
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Description Tags" },
  ];
  private toast;

  private product = {
    id: 0,
    name: "",
    category: "",
    description: "",
    status: "",
  };

  private allCategories = [
    { key: "color", value: "Color" },
    { key: "condition", value: "Condition" },
    // { key: "fabric", value: "Fabric" },
    { key: "type", value: "Type / Waivers" },
    { key: "category", value: "Category" },
  ];

  private dialogTitle;
  private dialogCallback;
  private submitted = false;

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.DescriptionTag = new DescriptionTag();
    this.toast = new Toaster();
    this.imagePath = this.DescriptionTag.getBaseURL() + "uploads/services/";
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.product = {
      id: 0,
      name: "",
      category: "",
      description: "",
      status: "",
    };

    this.submitted = false;
    this.dialogTitle = "Add New Category";
    this.productDialog = true;
  }

  //CLOSE THE ITEM DAILOG BOX
  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(e) {
    e.preventDefault();

    this.submitted = true;
    if (this.product.name.trim()) {
      if (this.product.id != 0) {
        this.DescriptionTag.updateItem(this.product).then((res) => {
          this.loadList(0);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      } else {
        this.DescriptionTag.saveItem(this.product).then((res) => {
          this.loadList(0);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      }

      this.productDialog = false;
      this.product = {
        id: 0,
        name: "",
        category: "",
        description: "",
        status: "",
      };
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.submitted = false;
    this.dialogTitle = "Update Category";
    this.productDialog = true;

    this.DescriptionTag.getItem(data).then((res) => {
      if (res.length > 0) {
        //FIND THE CATEGORY TYPE AND MAKE IT AS SELECTED IN EDIT DIALOG BOX.
        this.selectedCategory = res[0].category_type;
        this.allCategories.filter((elem) => {
          if (elem.key == this.selectedCategory) {
            this.selectedCategoryObj = elem;
          }
        });

        this.product = {
          id: res[0].id,
          name: res[0].category_name,
          category: this.selectedCategoryObj,
          description: res[0].description,
          status: res[0].status,
        };
      }
    });
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmChangeStatus(data, status) {
    this.product = {
      id: data.id,
      name: data.category_name,
      category: "",
      description: "",
      status: data.status,
    };
    this.productStatus = status;
    this.statusDialog = true;
  }

  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  changeStatus() {
    this.statusDialog = false;
    this.product.status = this.productStatus;
    this.DescriptionTag.changeStatus(this.product).then((res) => {
      this.loadList(0);
      //SHOW NOTIFICATION
      this.toast.handleResponse(res);
    });
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.DescriptionTag.getItems(page).then((data) => {
      this.lists = data.catagory_lists;
      this.totalRecords = data.total_count_category;
      this.limit = data.limit_per_page;
    });
  }

  loadSearchData() {
    this.submitted = true;
    if (this.keyword) {
      this.DescriptionTag.getSearchedDescTags(this.keyword).then((data) => {
        this.lists = data.catagory_lists;
        this.checkPagination = data.pagination;
      });
    }
  }
}
